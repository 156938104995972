import React from 'react';
import { graphql } from 'gatsby';
import  {useState,useRef} from 'react';
import Layout from '../components/layout';

// https://react-icons.github.io/react-icons/icons?name=fa
import { FaMinus, FaPlus ,FaRegQuestionCircle} from 'react-icons/fa';

import Overlay from 'react-bootstrap/Overlay';

// import PersonalBlog from '../containers/home';
import SEO from '../components/seo';
import { SalaryPaycheck } from 'dutch-tax-income-calculator';
import 'chart.js/auto';
import { Chart as ChartJS, ArcElement, Tooltip, Legend,DataLabel } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Doughnut } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);
import {Adsense} from '@ctrl/react-adsense';
// useEffect(() => {
//   // console.log("Component has been rendered");
//   handleChange(null, "page-load", salary.income)
// }, []);




// End
const HomePage = (props: any) => {
   

  const popoverText = '<b>Tax benefit</b> that allows highly skilled migrants who meet certain conditions to receive a tax-free allowance equal to 30% of their gross salary. This means that they only pay <b>income tax on 70% of their salary</b>';
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const { data } = props;
  const [rulling, setRulling] = useState(false);
  const [data1, setData1] = useState({
    labels: ["Taxes", "Net Income"],
    datasets: [
      {
        // label: ["55%","45%"],
        data: [4583-3309,3309],
        backgroundColor: [
          "rgba(253, 126, 20, 1)",
          "rgba(253, 126, 20, 0.1)",

        ],
        borderColor: [
          "rgba(253, 126, 20, 0.1)",
          "rgba(253, 126, 20,  0.1)",
        ],
        borderWidth: 1
      },
      
    ]
  });
  const [options, setOptions] = useState({});
  const [salary, setSalary] = useState({
    income: 55000,
    netYear: 0,
    grossMonth: 0,
    netMonth: 0,
    taxableYear: 0,
    payrollTax: 0,
    socialTax: 0,
    generalCredit: 0,
    labourCredit: 0
  });

  const [chartLoaded, setChartLoaded] = useState(false);

  React.useEffect(() => {
    // console.log("Component has been rendered");
    handleChange(null, "page-load", salary.income)
    if (!chartLoaded) {
      setChartLoaded(true);
    }
}, []);
// console.log(rulling,salary)
  const rullingChange = (event:any) => {
    console.log("Rulling click", event.target.checked)
    handleChange(event.target.checked, event.target.checked, salary.income)
  }


  const handleAdd = (event:any) => {
    

    const subsIncome = salary.income =salary.income + 1000 
    setSalary({ ...salary, income: subsIncome});

    handleChange(event,  rulling,subsIncome ) 
    // console.log("Rulling", rulling, rullinGet(rulling))
  }
  const handleSubtract = (event:any) => {
    
    const subsIncome = salary.income =salary.income - 1000 

    setSalary({ ...salary, income: subsIncome});
    handleChange(event,rulling, subsIncome ) 
    // console.log("Rulling", rulling, rullinGet(rulling))
  
  }
  
  function hasLeadingZero(num) {
    const numStr = num.toString(); // convert number to string
    return numStr.startsWith('0'); // check if string starts with "0"
  }
  // For calculation instructions:
  const handleChange = (event:any, flow:any, income_rulling:any) => {

    // Slise first 0 in input field,when re-typing values
    if(income_rulling){
      console.log("hasLeadingZero",hasLeadingZero(income_rulling))
    }
    else{
      console.log("Value",event)
      setSalary(salary.income, parseInt(event.target.value.slice(1)));
      console.log("salary",salary)
    }
   
    // const inputValue = income_rulling.toString();

    // // Remove leading zero if it's the first character and input value is not 0
    // if (inputValue.length > 0 && inputValue.charAt(0) === "0" && inputValue !== "0") {
    //   setSalary({...salary, income: parseInt(inputValue.slice(1))});
    // } else {
    //   setSalary({...salary, income: parseInt(inputValue)});
    // }

    let rulling_value, income;
  
    if (flow == "rulling" || flow == true) {
      rulling_value = true;
      income = Number(salary.income);
      // // console.log("Rulling Calculation")
    } else if (flow == "page-load" || flow == false ) {
      rulling_value = false;
      income = Number(income_rulling);
      // console.log("Page Load")
    } else {
      rulling_value = false;
      income = Number(event.target.value);
      // console.log("Regular Calculation")
    }
    console.log("R", income, "Rulling:", rulling_value,"Flow ",flow)
  
    const paycheck = new SalaryPaycheck({
      income: income,
      allowance: false,
      socialSecurity: true,
      older: false,
      hours: 40,
    }, 'Year', 2023, {
      checked: rulling_value,
      choice: 'normal',
    });
  
    setRulling(rulling_value);
    setSalary({
      income:  Math.round(income),
      netYear: Math.round(paycheck.netYear),
      grossMonth: Math.round(paycheck.grossMonth),
      netMonth: Math.round(paycheck.netMonth),
      taxableYear: Math.round(paycheck.taxableYear),
      payrollTax: Math.round(paycheck.payrollTax),
      socialTax: Math.round(paycheck.socialTax),
      generalCredit: Math.round(paycheck.generalCredit),
      labourCredit: Math.round(paycheck.labourCredit),
    });


    //  Chart Logic 

    setData1({
      labels: ["Net Income", "Taxes"],
      datasets: [
        {
          // label: ["55%","45%"],
          data: [Math.round(paycheck.netMonth), Math.round(paycheck.grossMonth - paycheck.netMonth)],
          backgroundColor: [
            "rgba(253, 126, 20, 1)",
            "rgba(253, 126, 20, 0.1)",
  
          ],
          borderColor: [
            "rgba(253, 126, 20, 0.1)",
            "rgba(253, 126, 20,  0.1)",
          ],
          borderWidth: 1
        },
        
      ]
    })
    
  
    const tooltipCallback = (context:any) => {
      console.log("Context",context)
  
     
  
      if (context &&  context.dataset) {
        // const label = data1.labels[context[0].dataIndex];
        // const value = context[0].raw;
        return context.dataset.data.map((value) => {
          const total =Math.round(paycheck.grossMonth ) //context.dataset.data.reduce((acc, curr) => acc + curr);
          return [total,value,((value / total) * 100).toFixed(2)];
        });
  
  
        // return `${percentages}%`;
      } else {
        console.log("Missing")
        return '';
      }
    };
  
    setOptions({
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 2,
      plugins: {
        tooltip: {
          callbacks: {
            label: function(tooltipItem:any) {
              // console.log(tooltipItem)
              var dataset = tooltipItem.dataset.data[tooltipItem.dataIndex];
              // console.log(tooltipItem,dataset)
              var total = tooltipItem.dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                return previousValue + currentValue;
              });
              var currentValue =tooltipItem.dataset.data[tooltipItem.dataIndex];
              var precentage = Math.floor(((currentValue/total) * 100)+0.5);         
              return precentage + "%";
            }
          }
      },
      datalabels: {
        formatter: (value, ctx) => {
        
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map(data => {
              sum += data;
          });
          let percentage = (value*100 / sum).toFixed(0)+"%";
          return percentage;

      
        },
        color: function(ctx) {
          // use the same color as the border
          return ["white","#EE5F15"]
      },
      font: {
        // weight: 'bold',
        size: "15%",
      }
             }
    
    
    }

    })

    
  }


  return (
    <Layout>
      <SEO
        title="Dutch Tax Calculator (NET and GROSS) per month or Yearly and Related Taxes For Your Salary in the Netherlands"
        description={data.site.siteMetadata.description}
      />


      <div className="row">
        <div className="col text-center">
          <h1 className="h1-header mb-5">
          Calculate your taxes with ease, just <b className="info-buttons">input your income.</b> Try it now! </h1>
          {/* <h2 className="h1-header"> Calculate your taxes with ease, just input your salary. Try it now! </h2> */}
        </div>
      </div>
    
    {/* Main Body Section */}
      <div className="container mt-5" >


  <div className="row">
    <div className="col-md-12 col-sm-12 ml-auto mr-auto">

        <div className="row">

        <div className="col-md-6 col-sm-12 mb-4">
            
              <div className="col-md-8">
                  <div className="row">

                    <div className="form-group text-center text-xs-center text-sm-center text-md-start t
                    ext-lg-start">
                        <label htmlFor="salaryInput" className="bmd-label-floating">Your Gross Salary</label>
                        <div className="row">
     <div className="col-3 d-flex justify-content-end">
                        <button type="button"
      className="input-group-text "
      onClick={handleSubtract}
    >
      <FaMinus className="inc-buttons " />
    </button>
    </div>
    <div className="col-6">
                        <input type="number" className="form-control  text-center text-xs-center text-sm-center text-md-start text-lg-start inline-block" value={salary.income }  onChange={handleChange}  id="salaryInput"></input>

                        </div>
                        <div className="col-3 d-flex justify-content-start">      
        <button
          className="input-group-text inline-block"
          onClick={handleAdd}
        >
         <FaPlus className="inc-buttons " />
        </button>
        </div>
        </div>

                      </div>
                    </div>
                    <div className="row">
                      <div className="togglebutton mt-2 mt-md-5 text-center text-xs-center text-sm-center text-md-start text-lg-start" >
                          <p className="sm-inline">30% Rulling 
                          <span ref={target} onClick={() => setShow(!show)}>
                          <FaRegQuestionCircle className="ml-2 info-buttons "/>
      </span></p> 
      <Overlay target={target.current} show={show} placement="bottom">
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <div
            {...props}
            style={{
              position: 'absolute',
              backgroundColor: 'rgba(255, 255, 255, 0.85)',
              padding: '2px 10px',
              color: 'black',
              borderRadius: 3,
              width :'300px',
              ...props.style,
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: popoverText }} />
          </div>
        )}
      </Overlay>
                         

                          <label>
                            <input className="sm-inline"  type="checkbox"  value={rulling.checked }  onChange={rullingChange}></input>
                            <span className="toggle"></span>
                          </label>
                        </div>
                    </div>
                  </div>
                      

        </div>


        <div className="col-md-6 col-sm-12  mb-4">
            <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                <div className="card card-login">
      
                    <div className="card-header card-header-primary text-center">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 ml-auto mr-auto">
                                <h4 className="card-title">Year Net Income</h4>
                                <h2 className="card-title"><b>€ {salary.netYear}</b> </h2>
                            </div>
                            <div className="col-lg-12 col-md-12 ml-auto mr-auto mt-4">
                                <div className="row">
                                    <div className="col-md-6 border-r1px">
                                      <h5 className="card-title">Gross Month</h5>
                                      <h5 className="card-title"><b>€ {salary.grossMonth}</b></h5>
                                    </div>

                                    <div className="col-md-6 border-l1px">
                                        <h5 className="card-title">Net Month</h5>
                                        <h5 className="card-title"><b  className="net-income-style">€ {salary.netMonth}</b></h5>
                                      </div>

                                </div>
                          </div>
                        </div>



                    </div>


                    <div className="card-body mb-3" style={{  height: "auto" }}>
                    {chartLoaded
        ?  <Doughnut plugins={[ChartDataLabels]} className="canvas-container " data={data1 } options={options} />
        : null
      }



                    </div>
         

           
                </div>
              </div>
        </div>
      </div>



    </div>
  </div>
</div>
      {/* EMD */}



      {/* How we calculate */}
      <section className="bg-white py-10 mt-5">
                        <div className="container px-5 ">
                            <div className="row gx-5 text-center py-5">
                                <div className="col-lg-3 mb-5 mb-lg-0">
                                    <div className="icon-stack icon-stack-xl bg-gradient-primary-to-secondary text-white mb-4"></div>
                                    <h3> Up to date <b className="info-buttons"><br></br> 2023 Data</b> </h3>
                                    <p className="mb-0">Based on publicly available tax rates from Belastingdienst</p>
                                </div>
                                <div className="col-lg-3 mb-5 mb-lg-0">
                                    <div className="icon-stack icon-stack-xl bg-gradient-primary-to-secondary text-white mb-4"></div>
                                    <h3>Supports<br></br> <b className="info-buttons">30% Rulling</b> </h3>
                                    <p className="mb-0">Many highly skilled migrants can make use of 30% Rulling which gives very significant tax benefits ranging from 300-1000 euros Net per month.</p>
                                </div>

                                <div className="col-lg-3 mb-5 mb-lg-0">
                                    <div className="icon-stack icon-stack-xl bg-gradient-primary-to-secondary text-white mb-4"></div>
                                    <h3>Based on  <br></br><b className="info-buttons">40-hour</b> <br></br> workweek </h3>
                                    <p className="mb-0"> Using a 40-hour workweek as a reference for calculations provides a standardized measure of work hours that is widely accepted and understood. It allows for consistency in calculations and makes it easier to compare wages and salaries across different jobs and industries..</p>
                                </div>


                                <div className="col-lg-3">
                                    <div className="icon-stack icon-stack-xl bg-gradient-primary-to-secondary text-white mb-4"></div>
                                    <h3>Visual representation<br></br><b className="info-buttons">Net vs</b> <b className="info-buttons">Gross </b><br></br> Income</h3>
                                    <p className="mb-0">A tax calculator that visually presents net and gross income out of a total sum allows individuals to easily determine the percentage of their income that goes towards taxes. This can help with budgeting and financial planning by providing a clear breakdown of earnings and deductions.</p>
                                </div>
                            </div>
                        </div>
                    </section>
{/* End */}


<div id="adsense">
<Adsense
  client="ca-pub-1313261935496626"
   slot="7035643644"
  style={{ display: 'block' }}
  layout="in-article"
  format="auto"
  data-full-width-responsive="true"
/>
</div>

    </Layout>

  );
};

export default HomePage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
